import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function invalidPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    var phoneNumberRegExp = new RegExp(/^$|^(?=.*[0-9])[- +()0-9]+$/);
    const forbidden:Boolean = !phoneNumberRegExp.test(control.value);
    return forbidden ? {'invalidPhoneNumber': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[appInvalidPhoneNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: invalidPhoneNumberValidatorDirective, multi: true}]
})
export class invalidPhoneNumberValidatorDirective implements Validator {
  @Input() invalidPhoneNumber: string;

  validate(control: AbstractControl): {[key: string]: any} {
    
    return this.invalidPhoneNumber ? invalidPhoneNumberValidator()(control) : null;
  }
}