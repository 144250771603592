import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { Project } from '../project';
import { ContentService } from '../../content.service';
// import { MatDialog } from '@angular/material/dialog';
import { DialogContentComponent } from '../../dialog-content/dialog-content.component';
declare var $: any;

@Component({
    selector: 'app-projects-masonry',
    templateUrl: './projects-masonry.component.html'
})
export class ProjectsMasonryComponent implements OnInit {

    constructor(
        public c: ContentService,
        public projectService: ProjectService,
    ) { }

    ngOnInit(): void {
    }

    getRandomMasonrySizingClass(): string {
        // we need random sizing, sometimes : 
        // nothing
        // height2
        // width3
        // etc. 
        let directions = ["width", "height"];
        let multipliers = ["", "2", "3"];
        let direction_key: number = Math.floor(Math.random() * directions.length);
        let multiplier_key: number = Math.floor(Math.random() * multipliers.length);
        let masonerySizingClass: string = directions[direction_key] + multipliers[multiplier_key];
        return masonerySizingClass;
    }

    projectLinkClicked(project: Project) {
        if (!this.projectService.hasProjectAnUrl(project)) {
            $('#modal-project').modal('show')
            this.projectService.setProjectZoomed(project);
            return false
        }
    }

}
