import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, LowerCasePipe } from '@angular/common';
/***********/
import { ForbiddenValidatorDirective } from './contact/forbidden-name.directive';
import { invalidPhoneNumberValidatorDirective } from './contact/invalid-phone-number.directive';
import { ContentService } from './content.service';
import { SEOService } from './seo.service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { ToolsComponent } from './tools/tools.component';
import { ProjectsCarouselComponent } from './projects/projects-carousel/projects-carousel.component';
import { StoryComponent } from './story/story.component';
import { FooterComponent } from './footer/footer.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { OlderProjectsComponent } from './projects/older-projects/older-projects.component';
import { ProjectsMasonryComponent } from './projects/projects-masonry/projects-masonry.component';

const appRoutes: Routes = [
  { 
    path: ':lang', 
    component: AppComponent 
  },
  { 
    path: '',
    redirectTo: 'en',
    pathMatch: 'full'
  },
  { 
    path: '**', 
    component: AppComponent  
  }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled'
};

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, routerOptions
        ),
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
    ],
    declarations: [
        AppComponent,
        ForbiddenValidatorDirective,
        invalidPhoneNumberValidatorDirective,
        HeaderComponent,
        ContactComponent,
        ToolsComponent,
        ProjectsCarouselComponent,
        StoryComponent,
        FooterComponent,
        DialogContentComponent,
        NavigationComponent,
        LanguageSelectorComponent,
        OlderProjectsComponent,
        ProjectsMasonryComponent,
        DialogContentComponent
    ],
    exports: [DialogContentComponent],
    providers: [
        ContentService,
        SEOService,
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
