import { Component, AfterViewInit } from '@angular/core';
import { ContentService } from '../content.service';
declare var jquery:any;
declare var $ :any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
  
})
export class HeaderComponent {

  random_filename:string="";

  getRandomInt(min, max) {
    // min is included, max is not
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRandomDecorationImage() {
    let random_int = this.getRandomInt(1,7)
    let filename = "tapiocadesign-archi"+random_int+".webp";
    return filename;
  }

  ngAfterViewInit(){
      $(document).on('click', 'a[href^="#"].anchor-smooth', function(e) {
        // target element id
        var id = $(this).attr('href');
        // target element
        var $id = $(id);
        if ($id.length === 0) {
            return;
        }
        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();
        // top position relative to the document
        var pos = $id.offset().top;
        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
    });
  }

  constructor(
    public c:ContentService,
  ) {
    this.random_filename = this.getRandomDecorationImage();

  }


}
