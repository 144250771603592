import { Component } from '@angular/core';
import { ProjectService } from '../projects/project.service';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html'
})
export class DialogContentComponent {

  constructor(
    public c: ContentService,
    public projectService: ProjectService

  ) { }


}
