import { Injectable } from '@angular/core';
// import  *  as  content  from  './content.json';
import *  as  content from '../assets/content.json';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, catchError, map, tap, retry } from 'rxjs/operators';

@Injectable()
export class ContentService {

    public contentFromApi: any = {};
    public error: any;
    public mobileMenuState: Boolean;
    public currentLanguages: Array<string> = ["en", "fr"];
    public currentLanguageId: number = 0;
    public isContentFromApiLoaded: boolean = false;
    public siteTextContent: any = (content as any).default;
    public navigationItems: Array<string> = ["story", "projects", "tools", "contact"];

    public test: string = "DepuisContent";

    public getCurrentLanguageId(): number {
        return this.currentLanguageId;
    }

    public getLanguageIdFromLabel(label: string): any {
        return this.currentLanguages.indexOf(label);
    }

    private handleError(error: HttpErrorResponse): void {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        throw new Error('Something bad happened; please try again later.');
    };

    public t(path: Array<string>): string {
        try {
            // TRANSLATE
            // aDepth.anOtherDepth.aFinalDepth 
            // would be : [aDepth] [anOtherDepth] [aFinalDepth] 
            let translated: string;
            let lang: number = this.getCurrentLanguageId();
            if (path.length == 0) {
                throw "getTranslated path empty";
            } else if (path.length == 1) {
                translated = this.siteTextContent[path[0]][lang];
            } else if (path.length == 2) {
                translated = this.siteTextContent[path[0]][path[1]][lang];
            } else if (path.length == 3) {
                translated = this.siteTextContent[path[0]][path[1]][path[2]][lang];
            } else if (path.length == 4) {
                translated = this.siteTextContent[path[0]][path[1]][path[2]][path[3]][lang];
            } else if (path.length == 5) {
                translated = this.siteTextContent[path[0]][path[1]][path[2]][path[3]][path[4]][lang];
            } else if (path.length > 5) {
                throw "getTranslated too long";
            } else {
                console.error("t() path", path);
                throw "getTranslated false";
            }
            return translated;
        }
        catch (err) {
            throw new Error("err56dr1th5drt4h t() failed : " + JSON.stringify(path));
        }
    }

    public getLanguageLabel(): string {
        return this.currentLanguages[this.getCurrentLanguageId()];
    }
    public changeLanguage(currentLanguageId, isClickedFromMobileNav: Boolean) {
        if (isClickedFromMobileNav) {
            this.mobileMenuState = false;
        }
        this.currentLanguageId = currentLanguageId;
        return false;
    }

    public setContentFromApi(data) {
        this.contentFromApi = data;
        this.setContentFromApiLoaded();
    }

    public setContentFromApiLoaded() {
        this.isContentFromApiLoaded = true;
    }

    public c(section: string, property: string): string {
        return this.contentFromApi[this.getLanguageLabel()][section][property];
    }

    constructor(
        private http: HttpClient,
    ) {
        // console.log("this.siteTextContent", this.siteTextContent);
    }

}
