import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent {
  @Input() insideBurgerMenu: string;

  constructor() { }

  isInsideBurgerMenu ():boolean {
    if (this.insideBurgerMenu=="true") {
      return true;
    } else if (this.insideBurgerMenu=="false") {
      return false;
    } else {
      throw "insideBurgerMenu wrong value";
    }
  }

}
