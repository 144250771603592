export class Project { 

    imageFolder:string;
    slug:string;
    label:string;
    description:Array<string>;
    logoFileExtension:string;
    logoWidthPx:number;
    year:number;
    url:string;
    order:number;
    isShownByDefault:boolean;
  
    constructor(
      imageFolder:string,
      slug:string,
      label:string,
      description:Array<string>=["empty en","vide fr"],
      logoFileExtension:string="gif",
      logoWidthPx:number=null,
      year:number=0,
      url:string="",
      order:number=0,
      isShownByDefault:boolean=false,
        
       ) {
          this.imageFolder=imageFolder;
          this.slug=slug;
          this.label=label;
          this.description=description;
          this.logoFileExtension=logoFileExtension;
          this.logoWidthPx = logoWidthPx ? logoWidthPx : 60;
          this.year=year;
          this.url=url;
          this.order=order;
          this.isShownByDefault=isShownByDefault;
      
      }
  

  }
  
