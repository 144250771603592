import { Component } from '@angular/core';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { ContentService } from '../../content.service';

@Component({
  selector: 'app-projects-carousel',
  templateUrl: './projects-carousel.component.html'
  
})
export class ProjectsCarouselComponent {

  projectLinkClicked(project: Project) {
    if (!this.projectService.hasProjectAnUrl(project)) {
      return false
    }
  }

  constructor(
    public c: ContentService,
    public projectService: ProjectService,
  ) { }

}
