import {Injectable, Inject} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ContentService } from './content.service';

@Injectable()
export class SEOService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title, 
    private meta: Meta,
    private c: ContentService, 
    ) {
        
    }
    updateLang() {
      this.document.documentElement.lang = this.c.getLanguageLabel();
    }
    updateTitle() {
      this.title.setTitle(this.c.t(["title"]));
    }
    updateDescription() {
      let desc = this.c.t(["story","description-first"])
      this.meta.updateTag({ name: 'description', content: desc })
    }
}