import { ProjectService } from './projects/project.service';
import { SEOService } from './seo.service'
import { Component, HostListener } from '@angular/core';
import { ContentService } from './content.service';
import { Router, NavigationStart, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';

// declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  public browserWidthInPixelToSwitchMasonryAndCarousel:number = 768;
  public isMasonryAndNotCarousel:boolean;
  public timer;
  public timerId:number=0;

  /******************/
  public popup: any = {
    active: false,
    content: ""
  };
  public isPopupButtonVisible: Boolean;
  public currentLanguages: Array<String> = ["en", "fr"];
  public currentLanguageId = this.c.currentLanguageId;
  public siteTextContent;
  public path: any;

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      let browserWidth =  this.getBrowserWidth(event);
      this.setIsMasonryAndNotCarousel(this.isBrowserWidthInPixelAboveMasonryOrCarouselBreakpoint(browserWidth))
    }

  @HostListener('window:load', ['$event'])
    onLoad(event) {
      let browserWidth =  this.getBrowserWidth(event);
      this.setIsMasonryAndNotCarousel(this.isBrowserWidthInPixelAboveMasonryOrCarouselBreakpoint(browserWidth))
    }


  ngOnInit() {
    $(window).on("scroll touchmove", function () {
      let isPageScrolled: Boolean = $(document).scrollTop() > 0;
      $('header').toggleClass('tiny', isPageScrolled);
    });
  }

  openModal() {
    console.log("openModal")
    $('#project-label').modal('show')
  }

  setIsMasonryAndNotCarousel(is:boolean):void {
    // if there will be a change just below
    let willSwitch:boolean = false;
    willSwitch = is==this.isMasonryAndNotCarousel ? false : true
    this.isMasonryAndNotCarousel = is;
  }

  getBrowserWidth(event):number {
    return window.innerWidth
  }

  isBrowserWidthInPixelAboveMasonryOrCarouselBreakpoint(browserWidth:number):boolean {
    return browserWidth >= this.browserWidthInPixelToSwitchMasonryAndCarousel ? true : false
  }

  unsetProjectZoomed() {
    this.projectService.zoomedProject = null;
  }

  setPopup(active: boolean) {
    if (active) {
      this.popup.active = true;
      this.popup.content = "Error: siteTextContent.products";
    } else {
      this.popup.active = false;
      this.popup.content = "";
    }
  }

  getKeyFromValueInArray(array: Array<any>, value: any): any {
    for (var i = 0; i < array.length; i++) {
      if (array[i] == value) {
        return i;
      }
    }
    return false;
  }

  constructor(
    public c: ContentService,
    public projectService: ProjectService,
    public SEOService: SEOService,
    router: Router,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = event.url;
        const tree: UrlTree = router.parseUrl(url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (g) {
          const s: UrlSegment[] = g.segments;
          let lang = s[0].path;
          let langId: number = this.c.getLanguageIdFromLabel(lang);
          this.c.currentLanguageId = langId;
          // html index dom
          this.SEOService.updateLang()
          this.SEOService.updateTitle()
          this.SEOService.updateDescription()
        }
      }
    })
  }
}