import { Component } from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html'
})
export class StoryComponent {

  constructor(
    public c: ContentService
  ) { }

}
