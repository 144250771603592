import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  @Input() origin: string;

  isHeader():boolean {
    if (this.origin=="header") {
      return true;
    }
  }

  constructor(
    public c:ContentService,
  ) { }

}
