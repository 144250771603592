import { Component } from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html'
  // ,
  // styleUrls: ['./tools.component.sass']
})
export class ToolsComponent {

    public tools:any[] = [
        // name extension imageSizePercentage
        ["Angular","png", 35 ],
        ["React","svg", 35 ],
        ["Ionic","png" ],
        ["Bootstrap","svg" ],
        ["Tailwind","svg" ],
        ["Figma","svg", 20 ],
        ["Adobe","svg" ],
        ["Webdev","svg" ],
        // ["Symfony","svg" ],
        ["Laravel","svg" ],
        ["Drupal","svg" ],
        ["Wordpress","svg" ],

    ]

    getLogoSizePercentage(size:any) : number {
        // console.log("size", size)
        return size ? size : 25
    }

  constructor(
    public c: ContentService,
    
    


    
  ) { }

}
