import { Component } from '@angular/core';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { ContentService } from '../../content.service';
declare var $: any;

@Component({
    selector: 'app-older-projects',
    templateUrl: './older-projects.component.html'
})
export class OlderProjectsComponent {

    projectLinkClicked(project: Project) {
        if (!this.projectService.hasProjectAnUrl(project)) {
            $('#modal-project').modal('show')
            this.projectService.setProjectZoomed(project);
            return false
        }
    }

    constructor(
        public c: ContentService,
        public projectService: ProjectService,
    ) { }


}
