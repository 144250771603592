import { Component} from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  constructor(
    public c: ContentService,
  ) { }

}
