import { Injectable } from '@angular/core';
import { ContentService } from '../content.service';
import { Project } from './project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
	projects:Array<Project> = [];
    current:Project;
    // get first on by default
    zoomedProject: Project;
    
    setCurrent(project:Project) {
        this.current = project;
      }
    getCurrent():Project {
      return this.current;
    }
    setProjectZoomed(project: Project) {
      this.zoomedProject = project
    }
    hasProjectAnUrl(project:Project):boolean {
      return project.url!="";
    }
    getProjectHref(project:Project):string {
      return this.hasProjectAnUrl(project) ? "http://"+project.url : "#"
    }
    getProjectTarget(project:Project):string {
      return this.hasProjectAnUrl(project) ? "_blank" : "_self"
    }
    
    isProjectTargetBlank(project:Project):boolean {
      return this.hasProjectAnUrl(project) ? true : false
    }
    getImagePath(project:Project) {
      return './assets/img/portfolio/'+project.slug+'.jpg';
    }
    getProjectZoomed(): Project {
      return this.zoomedProject;
    }
    sortByOrder(){
      function compare(a,b) {
        if (a.order < b.order)
           return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }
      this.projects.sort(compare);
    }
    getProjects(areShownByDefault:boolean=false):Array<Project> {
        let filtered:Array<Project>=[];
        for (let project of this.projects) {
          if (areShownByDefault && project.isShownByDefault) {
            filtered.push(project);
          } else if (!areShownByDefault && !project.isShownByDefault)  {
            filtered.push(project);
          } 
        }
        if (filtered.length>0) {
          return filtered;
        } else {
          throw "getProjects() empty return !";
        }
    }
    getProjectsFromJson():Array<Array<any>> {
      return this.c.siteTextContent.projects.entities;
    }
    getDescriptionTranslated(description:Array<string>):string {
      let lang = this.c.getCurrentLanguageId();
      return description[lang];
    }
    
    constructor(
      private c:ContentService
     ) {
      let projectsData:Array<Array<any>> = this.getProjectsFromJson();
      for (let projectData of projectsData) {
        var i=0;
        let projectObject:Project = new Project(
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          projectData[i++],
          );
        this.projects.push(projectObject);
      }
      this.sortByOrder()
      this.zoomedProject = this.getProjects()[0];
      console.log("this.c.test",this.c.test);
    }
}