export class ContactForm {

	constructor(
	  public name: string,
	  public email: string,
	  public phone: string,
	  public message: string
	) {  }
  
  }
  