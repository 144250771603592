import { Component, OnInit } from '@angular/core';
import { ContactForm } from './contactForm';
import { ContentService } from '../content.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { invalidPhoneNumberValidator } from './invalid-phone-number.directive';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  public section:string = "section4_contact";
  public content:any;
  contactModel = {
    name: '', 
    email: '', 
    phone: "",
    message: ""
  };
  contactForm: UntypedFormGroup;
  contactFormStatus = {
    "isShown":false,
    "isSuccess":false,
    "statusMessage":"",

  }

  public model = new ContactForm("","","","");

    get name() { return this.contactForm.get('name'); }
    get email() { return this.contactForm.get('email'); }
    get phone() { return this.contactForm.get('phone'); }
    get message() { return this.contactForm.get('message'); }

    // TODO: Remove this when we're done
    get diagnostic() { return JSON.stringify(this.model); }

    public newContactForm() {
      let name = this.contactForm.controls["name"].value; 
      let email = this.contactForm.controls["email"].value;
      let phone = this.contactForm.controls["phone"].value;
      let message = this.contactForm.controls["message"].value;

      var formData: any = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);

      /****** contact form mail sender *****/
      var mailSenderUrl = "";

      // mailSenderUrl += "http://www.caelumfintech.ch/";
      // mailSenderUrl += "https://www.tapiocadesign.com";
      mailSenderUrl += ".";
      // mailSenderUrl += "http://localhost:4200/";
      // mailSenderUrl += "backend/mail_sender";

      // mailSenderUrl += "http://localhost:8888";
      // mailSenderUrl += "../mail";
      mailSenderUrl += "/mail";
      mailSenderUrl += "/index.php";
      // mailSenderUrl += "?";
      // mailSenderUrl += "name="+name;
      // mailSenderUrl += "&";
      // mailSenderUrl += "email="+email;
      // if (phone!=""){
      //   mailSenderUrl += "&"; 
      //   mailSenderUrl += "phone="+phone;
      // } 
      // mailSenderUrl += "&";
      // mailSenderUrl += "message="+message;
      
      interface ItemsResponse {response: string[];}

      this.http
      .post<any>(
          mailSenderUrl, 
          formData
        )
      .subscribe(
          data => {
            
            let responseMessage = data.message;

            this.contactFormStatus.isShown=true;
            this.contactFormStatus.isSuccess=true;
            this.contactFormStatus.statusMessage=responseMessage;
            
          },
          err => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              var msg = 'An error occurred: '+err.error.message;
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              var msg = 'Unsuccessful response code, Backend returned code '+err.status+', on url: '+mailSenderUrl+', body was: '+JSON.stringify(err.error.message);
            }
            this.contactFormStatus.isShown=true;
            this.contactFormStatus.isSuccess=false;
            this.contactFormStatus.statusMessage=msg;
            console.error(msg);
          }
        );
    }

    public path: any;

    ngOnInit() {
      this.contactForm = new UntypedFormGroup({
        'name': new UntypedFormControl(
          this.contactModel.name, 
          [
          Validators.required,
          Validators.minLength(4),
          ]),
        'email': new UntypedFormControl(
          this.contactModel.email, 
          Validators.required
          ),
        'phone': new UntypedFormControl(
          this.contactModel.phone,
          [
          invalidPhoneNumberValidator()
          ]
          ),
        'message': new UntypedFormControl(
          this.contactModel.message, 
          Validators.required
          )
      });

  }

  submitForm() {
    console.log("submitForm");
    var formData: any = new FormData();
    let name = this.contactForm.controls["name"].value; 
    let email = this.contactForm.controls["email"].value;
    let phone = this.contactForm.controls["phone"].value;
    let message = this.contactForm.controls["message"].value;  

    name = encodeURI(name);
    email = encodeURI(email);
    if (phone!=""){phone = encodeURI(phone)};
    message = encodeURI(message);

    formData.append("name", this.contactForm.get('name').value);
    formData.append("email", this.contactForm.get('email').value);
    formData.append("phone", this.contactForm.get('phone').value);
    formData.append("message", this.contactForm.get('message').value);
  }

  constructor(
    public http: HttpClient,
    public c: ContentService,
    public fb: UntypedFormBuilder,

) {

  this.contactForm = this.fb.group({
    name: [''],
    email: [''],
    phone: [''],
    message: [''],
  })

}

}
